import React from "react"
import loadable from "@loadable/component"
import parse from "html-react-parser"
import axios from "axios"
import qs from "qs"
import tw, { styled, theme } from "twin.macro"

import { graphql, useStaticQuery } from "gatsby"

import Layout from "../layouts/base"
import SEO from "../components/seo"

const Search = loadable(() => import("../components/search/index"))
const Results = loadable(() => import("../components/results"))
const Map = loadable(() => import("../components/map"))
const SVG = loadable(() => import("../components/svg"))
const Advantage = loadable(() => import("../partials/advantage"))

const Wrapper = styled.div`
	${tw`relative bg-asphalt text-granny`};

	@media (min-width: ${theme`screens.md`}) {
		min-height: 375px;
	}

	h1 {
		@media (min-width: ${theme`screens.md`}) {
			max-width: calc(100% - ${theme`screens.xs`});
		}

		@media (min-width: ${theme`screens.lg`}) {
			max-width: calc(100% - ${theme`screens.sm`});
		}

		@media (min-width: ${theme`screens.xl`}) {
			max-width: calc(100% - ${theme`screens.md`});
		}
	}

	.map-container {
		${tw`md:absolute top-0 right-0 w-full h-full`};

		@media (min-width: ${theme`screens.md`}) {
			max-width: ${theme`screens.xs`};
		}

		@media (min-width: ${theme`screens.lg`}) {
			max-width: ${theme`screens.sm`};
		}

		@media (min-width: ${theme`screens.xl`}) {
			max-width: ${theme`screens.md`};
		}
	}
`

const mapRef = React.createRef()
const request = {
	method: "GET",
	url: 'https://cms.checkthatproperty.com.au/api',
	params: {},
	responseType: "json",
}

const SearchPage = ({ location }) => {
	const siteTitle = "Search"
	const searched = qs.parse(location.search.replace(/^\?/, ""))
	const [state, setState] = React.useState({
		initialized: false,
		searched: searched?.q,
		searching: false,
		result: [],
	})

	const {
		wp: {
			searchPage: { headings: data },
		},
	} = useStaticQuery(graphql`
		query SearchPage {
			wp {
				searchPage {
					headings {
						showingResults
						nothingFound
						searchAgain
					}
				}
			}
		}
	`)

	const handleUpdate = data => {
		setState(state => ({
			...state,
			initialized: true,
			searched: data.q,
			searching: true,
		}))

		let myPromise = new Promise(resolve => resolve(data))

		if ("address" === data.type && (!data?.afd?.full_address || data.q !== data.afd.full_address)) {
			myPromise = getAddress(data.q)
				.then(response => ({
					...data,
					afd: response.data.data,
				}))
				.catch(error => {
					mapRef.current.updateMarker(null)

					throw {
						...error.response,
						type: "address",
					}
				})
		}

		myPromise
			.then(response => {
				mapRef.current.updateMarker(response.afd)

				return requestServer(response)
			})
			.catch(error => error.response)
			.then(response => {
				setState(state => ({
					...state,
					result: response?.data?.data || [],
					searching: false,
				}))
			})
	}

	const getAddress = q => {
		request.params = {
			action: "address_finder",
			args: qs.stringify({ q }),
		}

		return axios(request)
	}

	const requestServer = data => {
		request.params = {
			action: "search_products",
			args: qs.stringify(data),
		}

		return axios(request)
	}

	const maybeSearch = () => {
		if (!!searched?.q && !state.initialized) {
			const data = {
				q: searched.q,
				type: searched?.type ? searched.type : "address",
				...location.state?.metadata,
			}

			if (!data.state) {
				data.state = "NSW"
			}

			handleUpdate(data)
		}
	}

	return (
		<Layout className="isSearch">
			<SEO title={parse(siteTitle)} />

			<main>
				<Wrapper>
					<div className="container" tw="py-12 md:py-16 xl:py-20">
						<h1>
							<span tw="block mb-4 text-2xl text-white font-semibold">{parse(data.showingResults)}</span>

							{!!searched?.q ? searched.q : '""'}
						</h1>
					</div>

					<div className="map-container">
						<Map ref={mapRef} onLoad={maybeSearch} />
					</div>
				</Wrapper>

				<Results data={{ ...state, ...data }} />

				<div tw="py-12 md:py-16 xl:py-20 bg-asphalt text-white">
					<div className="container" tw="md:px-8 lg:px-16 xl:px-32">
						<h3 tw="text-2xl">{parse(data.searchAgain)}</h3>

						<Search default={{ ...searched }} trigger={handleUpdate} />
						<SVG width="34" height="43" id="double-up" tw="mt-4 text-granny" />
					</div>
				</div>

				<Advantage isSearch />
			</main>
		</Layout>
	)
}

export default SearchPage
